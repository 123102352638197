import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Layout from "../../layouts";
import SEO from "../../components/SEO";
import { Container, Section } from "../../components/LayoutUtils";
import IntroHeader from "../../components/IntroHeader";
import BlogPost from "../../components/BlogPost";
import AllBlogPosts from "../../components/AllBlogPosts";
import getPageImage from "../../utils/getPageImage";

const Blog = ({ data: { post, page }, pageContext }) => (
  <Layout>
    <SEO
      title={post.title}
      image={getPageImage(page)}
      description={post.body.markdown.excerpt}
    />
    <IntroHeader {...page} useH2 />
    <Container>
      <Section>
        <div className="row">
          <div className="col-md-4 d-none d-sm-none d-md-block">
            <AllBlogPosts currentPost={post} />
          </div>
          <div className="col-sm-12 col-md-8">
            <BlogPost
              post={post}
              prev={pageContext.prev}
              next={pageContext.next}
            />
          </div>
        </div>
      </Section>
    </Container>
  </Layout>
);

Blog.propTypes = {
  pageContext: PropTypes.shape({}).isRequired,
  data: PropTypes.shape({
    post: PropTypes.shape({}),
    page: PropTypes.shape({})
  }).isRequired
};

export const query = graphql`
  query($id: String!) {
    page: contentfulPage(slug: { eq: "blog" }) {
      ...page
    }
    post: contentfulBlogPost(slug: { eq: $id }) {
      ...blogPost
    }
  }
`;

export default Blog;
